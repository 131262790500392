import React, { useRef, useState, useEffect } from "react";
import modalStyle from "../../styles/components/Modal.module.scss";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentUserState, loginState, isFGDUserState } from "../../state/atoms";
import { fetchLegalNotice, acceptLegalNotice, fetchData, makeCookie, resetXSRFToken } from "../../helpers/functions";
import { IfaceAllNewestResponse, IfaceCurrentUser } from "../../helpers/interfaces";
import parse, { DOMNode, domToReact, HTMLReactParserOptions } from "html-react-parser";
import Gallery from "../article/Gallery";

type Props = {
    setShowPopup: any;
};

const options: HTMLReactParserOptions = {
    replace: (
        { attribs, children }: any // Touchup this 'any' type if can find supporting documentation in html-react-parser
    ) =>
        attribs &&
        attribs.class === "ck-gallery-content" && (
            <Gallery children={children} />
        ),
};

declare const document: any;

const LegalPopup = () => {
    // const [currentUser, setCurrentUser] = useRecoilState<any>(currentUserState);
    const currentUser: IfaceCurrentUser = useRecoilValue(currentUserState);
    const [loginAttempted, setLoginAttempted] = useState(false);
    const [isLoggedin, setIsLoggedin] = useRecoilState(loginState);
    const [noticeContent, setNoticeContent] = useState<any>(null);
    const [enableAccept, setEnableAccept] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(currentUser.accepted_legal_notice ? !currentUser.accepted_legal_notice : false);
    // const [showPopup, setShowPopup] = useState<boolean>(true);
    const [errorMsg, setErrorMsg] = useState<any>(null); // Feel Good Design Homepage
    const [isFGDUser, setFGDUser] = useRecoilState(isFGDUserState);

    const checkLogin = async () => {
        // console.log("checkLogin has been run");
        if (!loginAttempted && !isLoggedin) {
            try {
                const getUser = await fetchData("currentUser");
                if (getUser.error) {
                    makeCookie("initialUrl", window.location.pathname, 0.005);
                    setIsLoggedin(false);
                    setLoginAttempted(true);
                } else if (!getUser.error) {
                    // console.log("getUser", getUser);
                    setIsLoggedin(true);
                    // setCurrentUser(getUser.res.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const getLegalNoticeData = async () => {
        const LegalNoticeApi: IfaceAllNewestResponse = await fetchLegalNotice(isFGDUser);
        if (LegalNoticeApi.loaded && !LegalNoticeApi.error) {
            const topicData: any = LegalNoticeApi.res.data;
            setNoticeContent(topicData);
        } else {
            document.getElementsByTagName('body')[0].classList.remove('no-scroll');
        }
    }

    const checkUserAccpected = () => {
        if (currentUser.accepted_legal_notice !== null && currentUser.accepted_legal_notice !== undefined) {
            setShowPopup(!currentUser.accepted_legal_notice);
        }
        if (!currentUser.accepted_legal_notice) {
            document.getElementsByTagName('body')[0].classList.add('no-scroll');
        } else {
            document.getElementsByTagName('body')[0].classList.remove('no-scroll');
        }
    }

    useEffect(() => {
        getLegalNoticeData();
    }, [isFGDUser]);

    useEffect(() => {
        checkLogin();
    }, [isLoggedin, currentUser]);

    useEffect(() => {
        if(noticeContent && currentUser) {
            checkUserAccpected();
        }
    }, [currentUser, noticeContent]);

    const handleAccept = async (event: any) => {
        event.preventDefault();
        const updatedPreferences: IfaceAllNewestResponse = await acceptLegalNotice();
        if (updatedPreferences.loaded && !updatedPreferences.error) {
            setShowPopup(false);
            document.getElementsByTagName('body')[0].classList.remove('no-scroll');
        } else if (updatedPreferences.res) {
            const response: any = updatedPreferences.res;
            if (response.message) {
                if (response.message.includes('code 419')) {
                    resetXSRFToken();
                    setErrorMsg(<p>It looks like there may be a temporary problem with your account. Refresh and try again. If the problem continues, please reach out to <a href={'mailto:access.me@us.mcd.com'}>access.me@us.mcd.com</a> for assistance.</p>);
                } else if (response.message === "Request failed with status code 422") {
                    setErrorMsg(<p>User has already accepted or declined legal notice</p>)
                } else {
                    setErrorMsg(<p>{response.message}</p>);
                }
            }
        }
    }

    useEffect(() => {
        if (showPopup && noticeContent) {
            const container = document.getElementById('scroll-container');
            const maxHeight = container.clientHeight;
            const scrollHeight = container.scrollHeight;
            if (maxHeight >= scrollHeight && !enableAccept) {
                setEnableAccept(true);
            }
        }
    }, [noticeContent, showPopup]);

    return (
        <>
            {showPopup && noticeContent ? (
                <div className={`${modalStyle.dialogLayer}`}>
                    <div className={`${modalStyle.dialogLayerInner}`}>
                        <div className={`${modalStyle.background}`}></div>
                        <div role={'dialog'} id={'legal-dialog'} aria-modal={true}
                            className={`${modalStyle.dialogBox} ${modalStyle.legalPopupContainer}`}>
                            <div className={`${modalStyle.headerContainer}`}>
                                {noticeContent.title && <h2>{noticeContent.title}</h2>}
                                {noticeContent.subtitle && <p className={`${modalStyle.subtitle}`}>{noticeContent.subtitle}</p>}
                            </div>
                            <div id={'scroll-container'} className={`${modalStyle.bodyContainer}`}
                                onScroll={(event) => {
                                    let element = event.target as HTMLElement;
                                    if (element && (element.scrollHeight - element.scrollTop) <= (element.clientHeight + 10) && !enableAccept) {
                                        setEnableAccept(true);
                                    }
                                }}>
                                {noticeContent.notice_content ? parse(noticeContent.notice_content, options) : ''}
                            </div>
                            <div className={`${modalStyle.buttonContainer}`}>
                                <button className={`btn ${modalStyle.btn}`} onClick={(e) => {
                                    // setShowPopup(false);
                                    handleAccept(e);
                                }} disabled={!enableAccept}>Accept</button>
                                <p>(You won't need to do this again the next time you return.)</p>
                            </div>
                            <div className={`${modalStyle.errorContainer}`}>
                                {errorMsg && (
                                    <div className={modalStyle.errorMsg}>{errorMsg}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : <></>}
        </>
    )
}

export default LegalPopup;
