// React
import { useState, useEffect } from "react";
import styles from "styles/components/Gallery.module.scss";

// Next
import Image from "next/image";

// Import Swiper React components
import { 
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Thumbs 
} from 'swiper/modules';


import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

// Hardcoded data for testing FE
const tempData = [
    {
        src: "https://picsum.photos/id/250/1920/1080",
        alt: "ALT_TEXT_HERE",
        caption:
            "Optional caption. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    },
    {
        src: "https://picsum.photos/id/251/1920/1080",
        alt: "ALT_TEXT_HERE",
        caption:
            "Optional caption. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    },
    {
        src: "https://picsum.photos/id/252/1920/1080",
        alt: "ALT_TEXT_HERE",
        caption:
            "Optional caption. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    },
    {
        src: "https://picsum.photos/id/253/1920/1080",
        alt: "ALT_TEXT_HERE",
        caption:
            "Optional caption. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    },
    {
        src: "https://picsum.photos/id/254/1920/1080",
        alt: "ALT_TEXT_HERE",
        caption:
            "Optional caption. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    },
    {
        src: "https://picsum.photos/id/255/1920/1080",
        alt: "ALT_TEXT_HERE",
        caption:
            "Optional caption. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    },
    {
        src: "https://picsum.photos/id/256/1920/1080",
        alt: "ALT_TEXT_HERE",
        caption:
            "Optional caption. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    },
    {
        src: "https://picsum.photos/id/257/1920/1080",
        alt: "ALT_TEXT_HERE",
        caption:
            "Optional caption. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    },
    {
        src: "https://picsum.photos/id/258/1920/1080",
        alt: "ALT_TEXT_HERE",
        caption:
            "Optional caption. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
    },
];

const Gallery = ({ children = [] }) => {

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [data, setData] = useState(tempData);

    const getPhotoAttributes = (child) => {
        let thisImage = "";
        let thisCaption = "";
        // console.log("gallery child", child);
        // console.log("children", child.children);
        // console.log("image", child.querySelector("img"));
        child.children.forEach((element) => {
            if (element.name === "img") {
                thisImage = element.attribs.src;
            } else if (element.name === "figcaption") {
                element.children.forEach((text) => {
                    thisCaption = text.data;
                });
            }
        });
        const object = {
            src: thisImage,
            alt: thisCaption,
            caption: thisCaption,
        };

        return object;
    };

    // console.log("gallery children", children);
    console.log("gallery is loaded!");
    useEffect(() => {
        if (children) {
            let newPhotos = [];
            children.forEach((child) => {
                let thisImage = "";
                let thisCaption = "";
                console.log(child);
                child.children.forEach((element) => {
                    if (element.name === "img") {
                        thisImage = element.attribs.src;
                    } else if (element.name === "figcaption") {
                        element.children.forEach((text) => {
                            thisCaption = text.data;
                        });
                    }
                });
                const photo = {
                    src: thisImage,
                    alt: thisCaption,
                    caption: thisCaption,
                };

                if (photo.src !== "") {
                    newPhotos.push(photo);
                }
            });
            setData(newPhotos);
        }
    }, []);

    return (
        <div className={styles.gallery}>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Thumbs]}
                thumbs={{ swiper: thumbsSwiper }}
                spaceBetween={25}
                autoHeight={true}
            >
                {data.map((object, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <figure>
                                <Image
                                    unoptimized={true}
                                    src={object.src}
                                    alt={object.alt}
                                    width={1920}
                                    height={1080}
                                    layout="responsive"
                                    objectFit="cover"
                                    objectPosition="center"
                                    priority={true}
                                />
                                <figcaption>{object.caption}</figcaption>
                            </figure>
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            <Swiper
                onSwiper={setThumbsSwiper}
                watchSlidesVisibility
                watchSlidesProgress
                slidesPerView={5.5}
                className={styles.thumbnailSwiper}>
                {data.map((object, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <Image
                                unoptimized={true}
                                src={object.src}
                                alt={object.alt}
                                width={100}
                                height={100}
                                objectFit="cover"
                                objectPosition="center"
                            />
                        </SwiperSlide>
                    );
                })}
                {/* {data.forEach((image) => {
                    <SwiperSlide key={image.src}>
                        <Image width={100} height={100} src={image.src}></Image>
                    </SwiperSlide>;
                })} */}
            </Swiper>
        </div>
    );
};

export default Gallery;
