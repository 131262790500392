import { Component, ReactComponentElement, useState, useEffect } from "react";
import { AppProps } from "next/app";
import "../styles/main.scss";
import styles from "../styles/global/BaseStyles.module.scss";
// import { NextPage, NextPageContext } from "next";
import { RecoilRoot, useRecoilState } from "recoil";
import { useRouter } from "next/router";
import Analytics from "components/global/Analytics";
import LegalPopup from "../components/global/LegalPopup";

// Import Swiper styles
// import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";
// import "swiper/components/pagination/pagination.scss";
// import "swiper/components/scrollbar/scrollbar.scss";
// Additional Swiper Styles
// import "swiper/components/thumbs/thumbs.scss";
// import "swiper/components/a11y/a11y.scss";
import Head from "next/head";

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [isFGDLogin, setIsFGDLogin] = useState<any>(null);

  useEffect(() => {
    if(router) {
      if(router.pathname.includes('design/login') || router.pathname.includes('/reset-password')) {
        setIsFGDLogin(true);
      } else {
        setIsFGDLogin(false);
      }
    }
  }, [router]);

  if (typeof window !== "undefined") {
    let pathName = document.location.pathname;
    let hasAnchor = document.location.hash ? true : false;

    if(window.location && window.location.pathname.includes('/articles/')) {
      if (localStorage.getItem('scrollPosition_' + pathName) !== null && !hasAnchor) {
        let scrollPosition = localStorage.getItem('scrollPosition_' + pathName);
        setTimeout(function() {
          window.scrollTo(0, scrollPosition !== null ? parseInt(scrollPosition) : 0);
        }, 1000);
      } else {
        const hash = document.location.hash.replace('#', '');
        setTimeout(function() {
          const anchorSection = document.getElementById(hash);
          if (anchorSection) {
            window.scrollTo(0, anchorSection.getBoundingClientRect().top);
          }
        }, 1500);
      }
    }

    window.addEventListener('scroll', () => {
      let pathName = document.location.pathname;
      localStorage.setItem("scrollPosition_" + pathName, window.scrollY.toString());
    })
  }

  return (
    <RecoilRoot>
      {/*<Head>*/}
      {/*    <title>Now Loading Marketing Exchange</title>*/}
      {/*</Head>*/}
      {isFGDLogin !== null && isFGDLogin === false && <Analytics />}
      <main className={styles.mainContent}>
        {isFGDLogin !== null && isFGDLogin === false && <LegalPopup />}
        <Component {...pageProps} />
      </main>
    </RecoilRoot>
  );
}

export default MyApp;
