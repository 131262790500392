import { useEffect, useState } from "react";
import Head from "next/head";
import { useRecoilState } from "recoil";
import { currentUserState } from "../../state/atoms";

const tagGTM = process.env.NEXT_PUBLIC_GTM_TAG;
const tagGA = process.env.NEXT_PUBLIC_GA_TAG;

declare const window: any;

const Analytics = () => {
    const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
    const [userIdAdded, setUserIdAdded] = useState<boolean>(false);
    let userInfo = { ...currentUser };
    let track = userInfo && userInfo.roles !== undefined && userInfo.roles.length > 0 && (userInfo.roles[0] === 'superadmin' || userInfo.roles[0] === 'admin' || userInfo.roles[0] === 'fgd-admin' || userInfo.roles[0] === 'fg-admin') ? false : true;

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        if(currentUser.id) {
            window.dataLayer.push({ 
                "user_id": currentUser.id,
                // "audience_name": currentUser.employee_type ? currentUser.employee_type : 'agency'
             });
            setUserIdAdded(true);
        }
    }, [currentUser]);

    const isLocal: boolean = process.env.NEXT_PUBLIC_IMAGE_LOCAL && process.env.NEXT_PUBLIC_IMAGE_LOCAL === 'local' ? true : false;

    if (userInfo.id !== null && userInfo.id !== undefined && tagGTM && userIdAdded && !isLocal && track) {
        
        return (
            <>
                <Head>
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','${tagGTM}');`,
                        }}></script>
                    {/* <script
                        async
                        src={`https://www.googletagmanager.com/gtag/js?id=${tagGTM}`}></script> */}
                </Head>

                <noscript
                    dangerouslySetInnerHTML={{
                        __html: `<iframe src="//www.googletagmanager.com/ns.html?id=${tagGTM}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
                    }}></noscript>
            </>
        );
    } else {
        return <></>;
    }
};

export default Analytics;
