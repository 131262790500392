import axios from "axios";
import { ifacePageComment, IfaceTopicApi } from "./interfaces";
import dayjs from "dayjs";
import { filter } from "lodash";
import DOMPurify from 'dompurify';

const admin_url = process.env.NEXT_PUBLIC_API_URL;
const environment = process.env.NEXT_PUBLIC_ENV;

export const getCookies = () => {
	const pairs: any = document.cookie.split(";");
	const cookies: any = {};
	for (var i = 0; i < pairs.length; i++) {
		let pair: any = pairs[i].split("=");
		let key: any = (pair[0] + '').trim();
		if (cookies[key] !== undefined && cookies[key] !== null) {
			key += '-2';
		}

		cookies[key] = unescape(pair.slice(1).join('='));
	}
}

var deleteCookie = async (name: string) => {
	let domain: string = '';
	let domain_two: any = null;
	if (environment === 'Local') {
		domain = '.mcd.test';
	} else if (environment === 'Development') {
		domain = '.kmpadmin81.cliquedomains.com';
	} else if (environment === 'Staging' || environment === 'Production') {
		domain = '.me.mcd.com';
		domain_two = '.staging.me.mcd.com';
	}
	document.cookie = await name + `=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=${domain};`;
	if (domain_two) {
		document.cookie = await name + `=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=${domain_two};`;
	}
};

export const resetXSRFToken = async () => {
	//Delete Current Token
	deleteCookie('XSRF-TOKEN');

	axios.get(`${admin_url}/sanctum/csrf-cookie`)
		.then((response) => {
			console.log('response', response);
			setTimeout(() => {
				window.location.reload();
			}, 1000);
			return response;
		})
		.catch((error) => {
			console.log('error', error);
			return null;
		});
}

export const apiCall = (api: string) => {
	axios
		.get(`${admin_url}/api/${api}`.replace("//api", "/api"), {
			// TODO: enable when pulling from live API
			withCredentials: true,
		})
		.then(({ data }) => {
			return data;
		})
		.catch((error) => {
			console.log(error);
			return null;
		});
};

export const fetchData = async (
	apiUrl: string,
	context: any = null,
	local: boolean = false,
	retries: number = 0
) => {
	let url = `${admin_url}/api/${apiUrl}`;
	if (local) {
		url = `/api/${apiUrl}`;
	}

	const response = await axios
		.get(url.replace("//api", "/api"), {
			// .replace in case of trailing slash in .env file
			// TODO: enable when pulling from live API
			withCredentials: context === null ? true : false,
			// Attempt to pass browser httpOnly cookies into request
			headers:
				context !== null
					? { cookie: context.req.headers.cookie }
					: undefined,
		})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
			data: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => {
			return ({
				loaded: true,
				error: true,
				raw: JSON.parse(JSON.stringify(thisError)),
				res: JSON.parse(JSON.stringify(thisError)),
				data: JSON.parse(JSON.stringify(thisError)),
				// res: {
				//     // status: thisError.response.status,
				//     // headers: thisError.response.headers,
				//     // data: thisError.response.data,
				//     // data: thisError,
				//     // error: JSON.parse(JSON.stringify(res.error)),
				//     data: JSON.parse(JSON.stringify(thisError)),
				// },
			});
		});
	return response;
};

export const fetchMetaData = async (content: any) => {
	const endpoint: any = 'content/meta-data';
	const url = `${admin_url}/api/${endpoint}`.replace("//api", "/api"); // for extra slashes
	let params: any = {};
	if (content) {
		if (content.articles) {
			params['articles'] = content.articles;
		}
		if (content.briefs) {
			params['briefs'] = content.briefs;
		}
		if (content.campaigns) {
			params['campaigns'] = content.campaigns;
		}
		if (content.masterClasses) {
			params['master-classes'] = content.masterClasses;
		}
	}
	const response = await axios({
		method: "get",
		url: url,
		params: params,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const putData = async (
	endpoint: string,
	bodyFormData: any, // TODO: Create interface for this
	method: "put" | "post" = "put", // Default to 'put'
	retries: number = 0
) => {
	let url = `${admin_url}/api/${endpoint}${method === "put" ? "?_method=PUT" : ""
		}`.replace("//api", "/api"); // for extra slashes
	const response = await axios({
		method: "post",
		url: url,
		data: bodyFormData,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => {
			return ({
				loaded: true,
				error: true,
				raw: JSON.parse(JSON.stringify(thisError)),
				res: JSON.parse(JSON.stringify(thisError)),
			})
		});
	return response;
};

export const updateEmployeeType = async (params: any) => {
	const bodyFormData = {
		first_name: params.first_name,
		last_name: params.last_name,
		name: params.name,
		employee_type: params.employee_type
	};
	// console.log(bodyFormData);
	let url = `${admin_url}/api/user/profile/${params.id}?_method=PUT`.replace("//api", "/api"); // for extra slashes
	const response = await axios({
		method: "post",
		url: url,
		params: bodyFormData,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const resetUser = async (params: any) => {
	const bodyFormData = {
		first_name: params.first_name,
		last_name: params.last_name,
		name: params.name,
		employee_type: params.employee_type
	};
	// console.log(bodyFormData);
	let url = `${admin_url}/api/user/profile/${params.id}?_method=PUT`.replace("//api", "/api"); // for extra slashes
	const response = await axios({
		method: "post",
		url: url,
		params: bodyFormData,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;

}

// export const putData = async (
//     apiUrl: string,
//     body: any, // TODO: Create interface for this
//     context: any = null,
//     local: boolean = false
// ) => {
//     let url = `${admin_url}/api/${apiUrl}`;
//     if (local) {
//         url = `/api/${apiUrl}`;
//     }
//     const response = await axios
//         .put(url.replace("//api", "/api"), body, {
//             withCredentials: true,
//             headers: {
//                 Accept: "application/json",
//                 "Content-Type": "multipart/form-data",
//             },
//             data: body,
//         })
//         .then((res) => ({
//             loaded: true,
//             error: false,
//             raw: JSON.parse(JSON.stringify(res)),
//             res: JSON.parse(JSON.stringify(res.data)),
//         }))
//         .catch((thisError) => ({
//             loaded: true,
//             error: true,
//             raw: JSON.parse(JSON.stringify(thisError)),
//             res: JSON.parse(JSON.stringify(thisError)),
//             data: JSON.parse(JSON.stringify(thisError)),
//             // res: {
//             //     // status: thisError.response.status,
//             //     // headers: thisError.response.headers,
//             //     // data: thisError.response.data,
//             //     // data: thisError,
//             //     // error: JSON.parse(JSON.stringify(res.error)),
//             //     data: JSON.parse(JSON.stringify(thisError)),
//             // },
//         }));
//     return response;
// };

export const checkIfEmpty = (obj: object) => {
	return Object.keys(obj).length === 0;
};

/**
 * Get the value of a cookie
 * Source: https://gist.github.com/wpsmith/6cf23551dd140fb72ae7
 * @param  {String} name  The name of the cookie
 * @return {String}       The cookie value
 */
export const getCookie = (name: string) => {
	let value = `; ${document.cookie}`;
	let parts = value.split(`; ${name}=`);
	if (parts.length === 2 && parts !== undefined)
		return parts.pop()?.split(";").shift();
};

export const makeCookie = (name: string, value: string, days: number) => {
	var expires;
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = "; expires=" + date.toUTCString();
	} else {
		expires = "";
	}
	document.cookie = name + "=" + value + expires + "; path=/";
};

export function clearCookie(name: string) {
	document.cookie = name + "=; Max-Age=-99999999;";
}

export const setInnerHtml = (string: string) => {
	return {
		__html: string,
	};
};

/**
 * Get the URL parameters
 * source: https://css-tricks.com/snippets/javascript/get-url-variables/
 * @param  {String} url The URL
 * @return {Object}     The URL parameters
 */
export const getParams = function (url: string) {
	let params: any = {};
	let parser = document.createElement("a");
	parser.href = url;
	let query = parser.search.substring(1);
	let vars = query.split("&");
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split("=");
		params[pair[0]] = decodeURIComponent(pair[1]);
	}
	return params;
};

export const s3encoding = (url: string) => {
	const encodings: any = {
		'\+': "%2B",
		'\!': "%21",
		'\"': "%22",
		'\#': "%23",
		'\$': "%24",
		'\&': "%26",
		'\'': "%27",
		'\(': "%28",
		'\)': "%29",
		'\*': "%2A",
		'\,': "%2C",
		'\:': "%3A",
		'\;': "%3B",
		'\=': "%3D",
		'\?': "%3F",
		'\@': "%40",
	};

	return encodeURI(url)
		.replace('%40', '')
		.replace(/%25/g, '%')
		// Do the standard url encoding
		.replace(
			/(\+|!|"|#|\$|&|'|\(|\)|\*|\+|,|:|;|=|\?|@)/img,
			function (match) { return encodings[match]; }
		);

}

export const processBannerImage = (
	url: string | null,
	isSearchResult = false
) => {
	const base = process.env.NEXT_PUBLIC_API_URL;
	const s3_bucket = process.env.NEXT_PUBLIC_S3_BUCKET;
	const environment: any = process.env.NEXT_PUBLIC_IMAGE_ENVIRONMENT || null;
	let revisedUrl = `${base}/storage/${url}`
		.replace("//storage", "/storage")
		.replace("/storage/storage", "/storage")
		.replace("/storage//storage", "/storage");
	if (url) {
		if (isSearchResult || environment === 'dev') revisedUrl = `https://${s3_bucket}/${s3encoding(url)}`.replace('//storage', '').replace('/storage', '');
	}
	if (url && url.includes("://")) {
		return url;
	} else if (url !== null) {
		return revisedUrl;
	} else {
		// no image, let's display the default
		return "/images/null-placeholder.png";
	}
};

export const processS3Image = (url: string | null) => {
	const s3_bucket = process.env.NEXT_PUBLIC_S3_BUCKET;
	// console.log("debug url is: ", url);
	// console.log("debug s3 url is: ", s3_bucket);
	if (url?.includes(`${s3_bucket}`)) {
		return url;
	}
	let revisedUrl = `https://${s3_bucket}${url}`;
	if (url && url.includes("://")) {
		return url.replace("/storage", "");
	} else if (url !== null) {
		return revisedUrl.replace("/storage", "");
	} else {
		// no image, let's display the default
		return "/images/default-user.svg";
	}
};

export const processVideoUrl = (id: any, service: any) => {
	const idArr: any = id.split('/');
	switch (service.toLowerCase()) {
		case "brightcove":
			let url: any = 'https://players.brightcove.net/';
			if (id.includes('https://')) {
				url = id;
			} else if (idArr.length > 1) {
				url += id;
			} else {
				url += '5443452538001/2Y3BHlgjC_default/index.html?videoId=' + id;
			}
			return url;
			break;
		case "vimeo":
			let vimeoUrl: string = 'https://player.vimeo.com/video/';
			vimeoUrl += idArr[0];
			if (idArr.length > 1) {
				vimeoUrl += '?h=' + idArr[1];
			}
			return vimeoUrl;
			break;
		case "youtube":
			return `https://www.youtube.com/embed/${id}`;
			break;
		default:
			return null;
			break;
	}
}

export const getFGDThumbnail = (slug: any) => {
	let fgdThumbnail: any = null;

	switch (slug) {
		case 'happy-meal':
			fgdThumbnail = '/images/icon-happy-meal.svg';
			break;
		case 'packaging':
			fgdThumbnail = '/images/icon-packaging.svg';
			break;
		case 'mccafe':
			fgdThumbnail = '/images/icon-mcafe.svg';
			break;
		case 'webcast-recordings':
			fgdThumbnail = '/images/icon-webcast-recordings.svg';
			break;
		case 'mcdelivery':
			fgdThumbnail = '/images/icon-mcdelivery.svg';
			break;
		case 'premium':
			fgdThumbnail = '/images/icon-premium.svg';
			break;
		case "mymcdonalds":
			fgdThumbnail = '/images/icon-mymcdonalds.svg';
			break;
		case 'masterbrand':
			fgdThumbnail = '/images/icon-masterbrand.svg';
			break;
		case 'mcflurry':
			fgdThumbnail = '/images/icon-mcflurry.svg';
			break;
		case 'feelgooddesign':
			fgdThumbnail = '/images/fgd-logo.png';
			break;
		default:
			break;
	}

	return fgdThumbnail;
}

export const sortTopicsFromApi = (topics: IfaceTopicApi) => {
	return [...topics.res.data].sort((a, b) => {
		// Sort by alphabetical order
		const textA = a.title.toUpperCase();
		const textB = b.title.toUpperCase();
		return textA < textB ? -1 : textA > textB ? 1 : 0;
	});
};

export const fetchTopic = async (slug: any) => {
	let url = `${admin_url}/api/topics/${slug}`.replace("//api", "/api");
	const response = await axios({
		method: "get",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	}).then((res) => ({
		loaded: true,
		error: false,
		raw: JSON.parse(JSON.stringify(res)),
		res: JSON.parse(JSON.stringify(res.data)),
	})).catch((thisError) => ({
		loaded: true,
		error: true,
		raw: JSON.parse(JSON.stringify(thisError)),
		res: JSON.parse(JSON.stringify(thisError)),
	}));

	return response;
}

export const fetchTopics = async () => {
	let url = `${admin_url}/api/menu/topics`.replace("//api", "/api");
	const response = await axios({
		method: "get",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	}).then((res) => ({
		loaded: true,
		error: false,
		raw: JSON.parse(JSON.stringify(res)),
		res: JSON.parse(JSON.stringify(res.data)),
	})).catch((thisError) => ({
		loaded: true,
		error: true,
		raw: JSON.parse(JSON.stringify(thisError)),
		res: JSON.parse(JSON.stringify(thisError)),
	}));

	return response;
}

export const fetchMarkets = async () => {
	let url = `${admin_url}/api/articles/markets`.replace("//api", "/api");
	const response = await axios({
		method: "get",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	}).then((res) => ({
		loaded: true,
		error: false,
		raw: JSON.parse(JSON.stringify(res)),
		res: JSON.parse(JSON.stringify(res.data)),
	})).catch((thisError) => ({
		loaded: true,
		error: true,
		raw: JSON.parse(JSON.stringify(thisError)),
		res: JSON.parse(JSON.stringify(thisError)),
	}));

	return response;
}

export const fetchUserProfile = async (user_id: number) => {
	let url = `${admin_url}/api/user/profile/${user_id}`.replace("//api", "/api");
	const response = await axios({
		method: "get",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	}).then((res) => ({
		loaded: true,
		error: false,
		raw: JSON.parse(JSON.stringify(res)),
		res: JSON.parse(JSON.stringify(res.data)),
	})).catch((thisError) => ({
		loaded: true,
		error: true,
		raw: JSON.parse(JSON.stringify(thisError)),
		res: JSON.parse(JSON.stringify(thisError)),
	}));

	return response;
}

export const fetchUserPreferences = async () => {
	let url = `${admin_url}/api/user/profile-preferences`.replace("//api", "/api");
	const response = await axios({
		method: "get",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	}).then((res) => ({
		loaded: true,
		error: false,
		raw: JSON.parse(JSON.stringify(res)),
		res: JSON.parse(JSON.stringify(res.data)),
	})).catch((thisError) => ({
		loaded: true,
		error: true,
		raw: JSON.parse(JSON.stringify(thisError)),
		res: JSON.parse(JSON.stringify(thisError)),
	}));

	return response;
}

export const updateUserPreferences = async (preferences: any) => {
	// console.log("debug passed filters are: ", filters);
	const bodyFormData: any = {
		"market": preferences.market,
		"topics": preferences.topics,
		"stamps": preferences.stamps,
		"queries": preferences.queries
	};
	// const bodyFormData: any = preferences;
	// console.log("debug bodyFormData", bodyFormData);
	const endpoint = "user/profile-preferences";
	const url = `${admin_url}/api/${endpoint}`.replace("//api", "/api"); // for extra slashes
	const response = await axios({
		method: "post",
		url: url,
		params: bodyFormData,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
};

export const fetchBookmarksFolders = async (user_id: number) => {
	const url = `${admin_url}/api/user/${user_id}/bookmarks/folders`.replace("//api", "/api");
	const response = await axios({
		method: "get",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	}).then((res) => ({
		loaded: true,
		error: false,
		raw: JSON.parse(JSON.stringify(res)),
		res: JSON.parse(JSON.stringify(res.data)),
	})).catch((thisError) => ({
		loaded: true,
		error: true,
		raw: JSON.parse(JSON.stringify(thisError)),
		res: JSON.parse(JSON.stringify(thisError)),
	}));

	return response;
}

export const checkReadStatus = async (article_id: number) => {
	const url = `${admin_url}/api/articles/recommended-for-me?articles[]=${article_id}`.replace("//api", "/api");
	const response = await axios({
		method: "get",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	}).then((res) => ({
		loaded: true,
		error: false,
		raw: JSON.parse(JSON.stringify(res)),
		res: JSON.parse(JSON.stringify(res.data)),
	})).catch((thisError) => ({
		loaded: true,
		error: true,
		raw: JSON.parse(JSON.stringify(thisError)),
		res: JSON.parse(JSON.stringify(thisError)),
	}));

	return response;
}

export const fetchRecForMeSearch = async (isInit: boolean = false, queries: any = null) => {
	const url = `${admin_url}/api/recommended-for-me`.replace("//api", "/api");

	let params: any = {
		cached: isInit ? 1 : 0
	};

	if (queries) {
		params['queries'] = queries;
	}

	const response = await axios({
		method: "get",
		url: url,
		params: params,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	}).then((res) => ({
		loaded: true,
		error: false,
		raw: JSON.parse(JSON.stringify(res)),
		res: JSON.parse(JSON.stringify(res.data)),
	})).catch((thisError) => ({
		loaded: true,
		error: true,
		raw: JSON.parse(JSON.stringify(thisError)),
		res: JSON.parse(JSON.stringify(thisError)),
	}));

	return response;
}

export const fetchBookmarkFoldersArticles = async (user_id: number, folder_id: number) => {
	const url = `${admin_url}/api/user/${user_id}/bookmarks/folders/${folder_id}`.replace("//api", "/api");
	const response = await axios({
		method: "get",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	}).then((res) => ({
		loaded: true,
		error: false,
		raw: JSON.parse(JSON.stringify(res)),
		res: JSON.parse(JSON.stringify(res.data)),
	})).catch((thisError) => ({
		loaded: true,
		error: true,
		raw: JSON.parse(JSON.stringify(thisError)),
		res: JSON.parse(JSON.stringify(thisError)),
	}));

	return response;
}

export const createBookmarkFolder = async (folder_name: string, $user_id: number) => {
	// console.log(`Toggling bookmark for article ID ${folder_name}`);
	const bodyFormData = {
		folder_name: folder_name,
	};
	// console.log("debug bodyFormData", bodyFormData);
	const endpoint = `user/${$user_id}/bookmarks/folders`;
	const url = `${admin_url}/api/${endpoint}`.replace("//api", "/api"); // for extra slashes
	const response = await axios({
		method: "post",
		url: url,
		params: bodyFormData,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
};

export const renameBookmarkFolder = async (folder_name: string, folder_id: number, $user_id: number) => {
	// console.log(`Toggling bookmark for article ID ${folder_name}`);
	const bodyFormData = {
		folder_name: folder_name,
	};
	// console.log("debug bodyFormData", bodyFormData);
	const endpoint = `user/${$user_id}/bookmarks/folders/${folder_id}?_method=PUT`;
	const url = `${admin_url}/api/${endpoint}`.replace("//api", "/api"); // for extra slashes
	const response = await axios({
		method: "post",
		url: url,
		params: bodyFormData,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
};

export const deleteBookmarkFolder = async (folder_id: number, $user_id: number) => {
	const endpoint = `user/${$user_id}/bookmarks/folders/${folder_id}`;
	const url = `${admin_url}/api/${endpoint}`.replace("//api", "/api"); // for extra slashes
	const response = await axios({
		method: "delete",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
};

export const toggleIsBookmarked = async (id: number) => {
	// console.log(`Toggling bookmark for article ID ${id}`);
	const bodyFormData = {
		article_id: id,
	};
	// console.log("debug bodyFormData", bodyFormData);
	const endpoint = "articles/bookmark";
	const url = `${admin_url}/api/${endpoint}`.replace("//api", "/api"); // for extra slashes
	const response = await axios({
		method: "post",
		url: url,
		params: bodyFormData,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
};

export const toggleBookmarkFolder = async (user_id: number, folder_id: number, bookmark_id: number) => {
	// console.log(`Toggling bookmark for article ID ${bookmark_id}`);
	const bodyFormData = {
		folder_id: folder_id,
		bookmark_id: bookmark_id,
	};
	// console.log("debug bodyFormData", bodyFormData);
	const endpoint = `user/${user_id}/bookmarks/folders/manage`;
	const url = `${admin_url}/api/${endpoint}`.replace("//api", "/api"); // for extra slashes
	const response = await axios({
		method: "post",
		url: url,
		params: bodyFormData,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
		}));
	// console.log(response);
	return response;
};

export const searchBookmarks = async (query: string, filters: any, bookmark_folder: any = null) => {
	// console.log("debug passed filters are: ", filters);
	const bodyFormData: any = {
		q: query,
		market: filters.markets && filters.markets.length ? filters.markets : null,
		"date-start": filters.dates && filters.dates.start !== null ? new Date(filters.dates.start) : null,
		"date-end": filters.dates && filters.dates.end !== null ? new Date(filters.dates.end) : null,
		sort: filters.sort, // TODO: pull from filters
	};
	if (filters.bookmark_folder !== undefined) {
		bodyFormData.folder_id = filters.bookmark_folder;
	}
	const endpoint = "bookmarks/search";
	const url = `${admin_url}/api/${endpoint}`.replace("//api", "/api"); // for extra slashes
	const response = await axios({
		method: "get",
		url: url,
		params: bodyFormData,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
};

export const checkBookmarkStatus = async (type: string, id: any) => {
	const url = `${admin_url}/api/bookmarks/${type}/is-bookmarked?ids[]=${id}`.replace("//api", "/api");
	const response = await axios({
		method: "get",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	}).then((res) => ({
		loaded: true,
		error: false,
		raw: JSON.parse(JSON.stringify(res)),
		res: JSON.parse(JSON.stringify(res.data)),
	})).catch((thisError) => ({
		loaded: true,
		error: true,
		raw: JSON.parse(JSON.stringify(thisError)),
		res: JSON.parse(JSON.stringify(thisError)),
	}));

	return response;
}

export const searchStravito = async (query: string, offset = 0, limit = 10) => {
	// const searchEndpoint = process.env.NEXT_PUBLIC_STRAVITO_ENDPOINT + "search";
	const searchEndpoint = "/api/files";
	const apiKey = process.env.NEXT_PUBLIC_STRAVITO_API_KEY;
	const queryEndpoint = `${searchEndpoint}?q=${query}&limit=${limit}&offset=${offset}`;
	const sanitizedQueryEndpoint = DOMPurify.sanitize(queryEndpoint);
	const config = {
		headers: {
			"x-api-key": apiKey,
		},
	};

	const response = await axios
		.get(sanitizedQueryEndpoint, config)
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
};
export const fetchStrativo = async (query: string, offset = 0, limit = 10) => {
	// const searchEndpoint = process.env.NEXT_PUBLIC_STRAVITO_ENDPOINT + "search";
	const searchEndpoint = "/api/files";
	const apiKey = process.env.NEXT_PUBLIC_STRAVITO_API_KEY;
	const queryEndpoint = `${searchEndpoint}?q=${query}&limit=${limit}&offset=${offset}`;
	// const myInit = {
	//     method: "GET",
	//     mode: "no-cors",
	//     headers: {
	//         "X-API-KEY": apiKey,
	//         Accept: "*/*",
	//         // "Content-Type": "application/json",
	//     },
	// };

	// const myRequest = new Request(queryEndpoint, myInit);
	// fetch(myRequest)
	//     .then(function (response) {
	//         return response;
	//     })
	//     .then(function (response) {
	//         console.log("response", response);
	//     })
	//     .catch(function (error) {
	//         console.log("error", error);
	//     });
	const res = await fetch(queryEndpoint, {
		method: "GET",
		// mode: "no-cors",
		headers: { "X-API-KEY": "XXoT6CfQ6s5WbKRrzCDHW61hizYMHhYN6xmJ07yX" },
	});
	return res;
};

export const toTimestamp = (date: string | number | Date | null = null) => {
	let unix_timestamp;
	if (date) {
		unix_timestamp = dayjs(date).unix() * 1000;
	} else {
		unix_timestamp = dayjs().unix() * 1000;
	}
	// console.log("timestamp", unix_timestamp);
	return unix_timestamp;
};

export const postReaction = async (
	id: number | string,
	reaction: "thumbs_up" | "thumbs_down" | "shamelessly_steal" | "i_want_more"
) => {
	let myData: any = {
		article_id: id,
		reaction: reaction,
	};
	if (reaction === null) {
		myData = { article_id: id };
	}
	let url = `${admin_url}/api/articles/react`.replace("//api", "/api"); // for extra slashes
	const response = await axios({
		method: "post",
		url: url,
		// headers: {
		//     "Content-Type": "multipart/form-data",
		// },
		data: myData,
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
};

export const usereReactionFeedback = async (article_id: number, requestData: any) => {
	// console.log("debug passed filters are: ", filters);
	let bodyFormData: any = {
		"body": requestData.body,
		"reactions": {
			"thumbs_up": requestData.reactions.thumbs_up,
			"thumbs_down": requestData.reactions.thumbs_down,
			"shamelessly_stolen": requestData.reactions.shamelessly_steal,
			"i_want_more": requestData.reactions.i_want_more
		}
	};
	// bodyFormData['body'] = requestData.body;
	// const bodyFormData: any = preferences;
	// console.log("debug bodyFormData", bodyFormData);
	const endpoint = `article/${article_id}/user-feedback`;
	const url = `${admin_url}/api/${endpoint}`.replace("//api", "/api"); // for extra slashes

	const response = axios.post(url, bodyFormData, {
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
};

export const fetchReviewComments = async (id: number) => {
	let url = `${admin_url}/api/article/review/${id}/comment`;
	const response = await axios
		// .replace in case of trailing slash in .env file
		.get(url.replace("//api", "/api"), {
			withCredentials: true,
		})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
			data: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
};

export const deleteReviewComment = async (comment: ifacePageComment) => {
	let url = `${admin_url}/api/article/review/${comment.article_id}/comment/${comment.comment_id}`;
	const response = await axios
		// .replace in case of trailing slash in .env file
		.delete(url.replace("//api", "/api"), {
			withCredentials: true,
		})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
			data: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
};

export const resolveReviewComment = async (comment: ifacePageComment) => {
	let url =
		`${admin_url}/api/article/review/${comment.article_id}/comment/${comment.comment_id}/resolve`.replace(
			"//api",
			"/api"
		);
	const response = await axios({
		method: "post",
		url: url,
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
			data: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
};

export const postNewReviewComment = async (
	comment: ifacePageComment
	// method: "put" | "post" = "put" // Default to 'put'
) => {
	let url =
		`${admin_url}/api/article/review/${comment.article_id}/comment`.replace(
			"//api",
			"/api"
		);
	const response = await axios({
		method: "post",
		url: url,
		data: {
			article_id: comment.article_id,
			x_position: comment.x_position,
			y_position: comment.y_position,
			body: comment.body,
		},
		// headers: {
		//     "Content-Type": "multipart/form-data",
		// },
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
};

export const sendForReview = async (id: number) => {
	let url = `${admin_url}/api/article/review/${id}/submit-to-editor`.replace(
		"//api",
		"/api"
	);
	const response = await axios({
		method: "post",
		url: url,
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
			data: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
};

export const fetchLegalNotice = async (isFGDUser: boolean = false) => {
	const url = `${admin_url}/api/legal-notice`.replace("//api", "/api");
	let params: any = null;
	if (isFGDUser) {
		params = {
			displayTo: 'FGD_Only_User'
		};
	}
	const response = await axios({
		method: "get",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		params: params,
		withCredentials: true,
	}).then((res) => ({
		loaded: true,
		error: false,
		raw: JSON.parse(JSON.stringify(res)),
		res: JSON.parse(JSON.stringify(res.data)),
	})).catch((thisError) => ({
		loaded: true,
		error: true,
		raw: JSON.parse(JSON.stringify(thisError)),
		res: JSON.parse(JSON.stringify(thisError)),
	}));

	return response;
}

export const acceptLegalNotice = async () => {
	const endpoint = `user-legal-notice`;
	const url = `${admin_url}/api/${endpoint}`.replace("//api", "/api"); // for extra slashes
	let retries: number = 0;
	const response = await axios({
		method: "post",
		url: url,
		params: {
			accepted: 1
		},
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => {
			return ({
				loaded: true,
				error: true,
				raw: JSON.parse(JSON.stringify(thisError)),
				res: JSON.parse(JSON.stringify(thisError)),
			})
		});
	return response;
};

export const isJsonString = (str: string) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
}

const magicBellApiKey = process.env.NEXT_PUBLIC_MAGICBELL_API_KEY;
const magicBellApiSecret = process.env.NEXT_PUBLIC_MAGICBELL_SECRET;

export const createMagicBellUser = async (email: any = null, id: any = null) => {
	const headers: any = {
		'X-MAGICBELL-API-SECRET': magicBellApiSecret,
		'X-MAGICBELL-API-KEY': magicBellApiKey,
		'Content-Type': 'application/json'
	};

	const data: any = {
		"user": {
			"email": email ? email : '',
			"external_id": `${id}`
		}
	};

	const response = await axios({
		method: "POST",
		url: "https://api.magicbell.com/users",
		headers: headers,
		data: data
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

const env_usr = process.env.NEXT_PUBLIC_ALGOLIA_USERS;

export const updatedMagicBellUserByEmail = async (email: any = null, id: any = null) => {
	const headers: any = {
		'X-MAGICBELL-API-SECRET': magicBellApiSecret,
		'X-MAGICBELL-API-KEY': magicBellApiKey,
		'Content-Type': 'application/json'
	};

	const data: any = {
		"user": {
			"email": email ? email.toLowerCase() : '',
			"external_id": `${id}`
		}
	};

	const response: any = email ? await axios({
		method: "DELETE",
		url: `https://api.magicbell.com/users/email:${email.toLowerCase()}`,
		headers: headers,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		})) : { loaded: false, error: false, raw: {}, res: {} };

	return response;
}

export const updatedMagicBellUserById = async (email: any = null, id: any = null) => {
	const headers: any = {
		'X-MAGICBELL-API-SECRET': magicBellApiSecret,
		'X-MAGICBELL-API-KEY': magicBellApiKey,
		'Content-Type': 'application/json'
	};

	const data: any = {
		"user": {
			"email": email ? email.toLowerCase() : '',
			"external_id": `${id}`
		}
	};

	const response = await axios({
		method: "PUT",
		url: `https://api.magicbell.com/users/external_id:${id}`,
		headers: headers,
		data: data
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const deleteMagicBellUser = async (id: any = null) => {
	const headers: any = {
		'X-MAGICBELL-API-SECRET': magicBellApiSecret,
		'X-MAGICBELL-API-KEY': magicBellApiKey,
		'Content-Type': 'application/json'
	};

	const response = await axios({
		method: "DELETE",
		url: `https://api.magicbell.com/users/external_id:${id}`,
		headers: headers,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const deleteMagicBellUserByEmail = async (email: any = null) => {
	const headers: any = {
		'X-MAGICBELL-API-SECRET': magicBellApiSecret,
		'X-MAGICBELL-API-KEY': magicBellApiKey,
		'Content-Type': 'application/json'
	};

	const response: any = email ? await axios({
		method: "DELETE",
		url: `https://api.magicbell.com/users/email:${email.toLowerCase()}`,
		headers: headers,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		})) : { loaded: false, error: false, raw: {}, res: {} };

	return response;
}

export const fetchNotifications = async (email: any = null, id: any = null) => {
	let headers: any = {
		"X-MAGICBELL-API-KEY": magicBellApiKey
	};
	if (id) {
		headers['X-MAGICBELL-USER-EXTERNAL-ID'] = id;
	} else if (email) {
		headers['X-MAGICBELL-USER-EMAIL'] = email;
	}
	const response = await axios({
		method: "GET",
		url: "https://api.magicbell.com/notifications",
		headers: headers
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const fetchHappyMealPage = async (slug: any) => {
	const url = `${admin_url}/api/happy-meals/${slug}`.replace("//api", "/api");

	const response = await axios({
		method: "GET",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const fetchMenu = async (slug: any) => {
	const url = `${admin_url}/api/menu/${slug}`.replace("//api", "/api");

	const response = await axios({
		method: "GET",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const fetchBriefPage = async (slug: any) => {
	const url = `${admin_url}/api/creative-effectiveness/briefs/${slug}`.replace("//api", "/api");

	const response = await axios({
		method: "GET",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const toggleBriefBookmark = async (id: any) => {
	const url = `${admin_url}/api/creative-effectiveness/brief/bookmark/`.replace("//api", "/api");
	const params = {
		model_id: id
	}

	const response = await axios({
		method: "POST",
		url: url,
		params: params,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const fetchShareAndSteal = async (title: any = null, params: any = null) => {
	const url = `${admin_url}/api/creative-effectiveness/share-and-steal/${title}`.replace("//api", "/api");
	console.log('S&S url', url);
	console.log('url title', title);
	const response = await axios({
		method: "GET",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		params: params,
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const fetchCampaignPage = async (slug: any) => {
	const url = `${admin_url}/api/creative-effectiveness/campaigns/${slug}`.replace("//api", "/api");

	const response = await axios({
		method: "GET",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const toggleCampaignBookmark = async (id: any) => {
	const url = `${admin_url}/api/creative-effectiveness/campaign/bookmark/`.replace("//api", "/api");
	const params = {
		model_id: id
	}

	const response = await axios({
		method: "POST",
		url: url,
		params: params,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const fetchAllCampaigns = async (params: any = null) => {
	let url: any = `${admin_url}/api/creative-effectiveness/campaigns`.replace("//api", "/api");
	const response = await axios({
		method: "GET",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		params: params,
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const fetchMasterClass = async (slug: any) => {
	const url = `${admin_url}/api/creative-effectiveness/master-classes/${slug}`.replace("//api", "/api");

	const response = await axios({
		method: "GET",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const toggleMasterClassBookmark = async (id: any) => {
	const url = `${admin_url}/api/creative-effectiveness/master-class/bookmark/`.replace("//api", "/api");
	const params = {
		model_id: id
	}

	const response = await axios({
		method: "POST",
		url: url,
		params: params,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const fetchAllMasterClasses = async () => {
	const url = `${admin_url}/api/creative-effectiveness/master-classes`.replace("//api", "/api");

	const response = await axios({
		method: "GET",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

export const fetchCEPage = async (slug: any) => {
	const url = `${admin_url}/api/creative-effectiveness/pages/${slug}`.replace("//api", "/api");

	const response = await axios({
		method: "GET",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res.data)),
		}))
		.catch((thisError) => ({
			loaded: true,
			error: true,
			raw: JSON.parse(JSON.stringify(thisError)),
			res: JSON.parse(JSON.stringify(thisError)),
		}));
	return response;
}

// CMM
export const fetchCMMHomepage = async () => {
	const url = `${admin_url}/api/core-menu-manual/homepage`.replace("//api", "/api");
  
	const response = await axios({
	  method: "GET",
	  url: url,
	  headers: {
		"Content-Type": "multipart/form-data",
	  },
	  withCredentials: true,
	})
	  .then((res) => ({
		loaded: true,
		error: false,
		raw: JSON.parse(JSON.stringify(res)),
		res: JSON.parse(JSON.stringify(res.data)),
	  }))
	  .catch((thisError) => ({
		loaded: true,
		error: true,
		raw: JSON.parse(JSON.stringify(thisError)),
		res: JSON.parse(JSON.stringify(thisError)),
	  }));
	return response;
  }

// Feel-Good Design Functions
export const registerFGDUser = async (bodyFormData: any) => {
	let url = `${admin_url}/feel-good-design/register`; // for extra slashes
	const response = await axios({
		method: "POST",
		url: url,
		data: bodyFormData,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res)),
		}))
		.catch((thisError) => {
			return ({
				loaded: true,
				error: true,
				raw: JSON.parse(JSON.stringify(thisError.response)),
				res: JSON.parse(JSON.stringify(thisError.response)),
			})
		});
	return response;
}

export const forgotUserPassword = async (bodyFormData: any) => {
	let url = `${admin_url}/feel-good-design/forgot-password`; // for extra slashes
	const response = await axios({
		method: "POST",
		url: url,
		data: bodyFormData,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res)),
		}))
		.catch((thisError) => {
			return ({
				loaded: true,
				error: true,
				raw: JSON.parse(JSON.stringify(thisError.response)),
				res: JSON.parse(JSON.stringify(thisError.response)),
			})
		});
	return response;
}

export const resetUserPassword = async (bodyFormData: any) => {
	let url = `${admin_url}/feel-good-design/reset-password`; // for extra slashes
	const response = await axios({
		method: "POST",
		url: url,
		data: bodyFormData,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res)),
		}))
		.catch((thisError) => {
			return ({
				loaded: true,
				error: true,
				raw: JSON.parse(JSON.stringify(thisError)),
				res: JSON.parse(JSON.stringify(thisError)),
			})
		});
	return response;
}

export const loginFGD = async (bodyFormData: any) => {
	let url = `${admin_url}/feel-good-design/login`; // for extra slashes
	const response = await axios({
		method: "POST",
		url: url,
		data: bodyFormData,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res))
		}))
		.catch((thisError) => {
			return ({
				loaded: true,
				error: true,
				raw: JSON.parse(JSON.stringify(thisError)),
				res: JSON.parse(JSON.stringify(thisError)),
				status: thisError.response.data
			})
		});
	return response;
}

export const logoutFGD = async () => {
	let url = `${admin_url}/feel-good-design/logout`; // for extra slashes
	const response = await axios({
		method: "POST",
		url: url,
		headers: {
			"Content-Type": "multipart/form-data",
		},
		withCredentials: true,
	})
		.then((res) => ({
			loaded: true,
			error: false,
			raw: JSON.parse(JSON.stringify(res)),
			res: JSON.parse(JSON.stringify(res)),
		}))
		.catch((thisError) => {
			return ({
				loaded: true,
				error: true,
				raw: JSON.parse(JSON.stringify(thisError)),
				res: JSON.parse(JSON.stringify(thisError)),
			})
		});
	return response;
}

// General Functions

export const slugify = (string: string) => {
	const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
	const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
	const p = new RegExp(a.split('').join('|'), 'g')

	return string.toString().toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
		.replace(/&/g, '') // Replace & with 'and'
		.replace(/[^\w\-]+/g, '') // Remove all non-word characters
		.replace(/\-\-+/g, '-') // Replace multiple - with single -
		.replace(/^-+/, '') // Trim - from start of text
		.replace(/-+$/, '') // Trim - from end of text
}

export const slugifyWithChar = (string: string) => {
	return string.toString().toLowerCase().split(' ').join('-');
}

export const capitalizeFirstLetter = (string: any) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const fetchPublicURL = () => {
	const publicEnv = process.env.NEXT_PUBLIC_ENV;
	let publicUrl: string = process.env.NEXT_PUBLIC_FE_URL ? process.env.NEXT_PUBLIC_FE_URL : 'https://frontend.kmp.test';
	if (publicEnv === 'Development') {
		publicUrl = 'https://frontenddev.kmpadmin81.cliquedomains.com/';
	} else if (publicEnv === 'Staging') {
		publicUrl = 'https://staging.me.mcd.com';
	} else if (publicEnv === 'Production') {
		publicUrl = 'https://me.mcd.com';
	}

	return publicUrl;
}