import { atom } from "recoil";
import { IfaceCurrentUser } from "helpers/interfaces";
import dayjs from "dayjs";

// interface ApiResponse {
//     loaded: boolean;
//     error: boolean;
//     res: {
//         data: [];
//     };
// }

export const navbarColorState = atom({
    key: "navbarColorState",
    default: "light",
});

export const scrollPositionState = atom({
    key: "scrollPositionState",
    default: 0,
});

export const loginState = atom({
    key: "loginState",
    default: false, // Set to true for dev only until login is confirmed working
});

export const isCoreMenuState = atom({
    key: "isCoreMenuState",
    default: false,
});

export const isFGDUserState = atom({
    key: "isFGDUserState",
    default: false,
});

export const isFGDAdminState = atom({
    key: "isFGDAdminState",
    default: false,
});

export const initViewState = atom({
    key: "intView",
    default: true,
});

export const currentUserState = atom({
    key: "currentUser",
    default: {
        areas_of_responsibility: null,
        avatar: null,
        country: null,
        email: null,
        id: null,
        job_title: null,
        market: null,
        ms_teams_name: null,
        name: null,
        pronouns: null,
        year_joined: null,
        employee_type: null,
        initiatives: null
    } as IfaceCurrentUser,
});

export const footerMenuState = atom({
    key: "footerMenuState",
    default: {
        loaded: false,
        error: false,
        res: {
            data: [{ title: "", url: "", target: "" }],
        },
    },
});

export const pageLoadState = atom({
    key: "pageLoadState",
    default: false,
});

export const showTopSearchState = atom({
    key: "showTopSearchState",
    default: true,
});

export const topicListState = atom({
    key: "topicListState",
    default: {
        loaded: false,
        error: false,
        res: {
            data: [
                {
                    id: 0,
                    title: "",
                    slug: "",
                },
            ],
        },
    },
});

export const menuState = atom({
    key: "menuState",
    default: {
        loaded: false,
        error: false,
        res: {
            data: [
                {
                    children: [],
                    name: "",
                    url: "",
                    target: "",
                    nested: 0,
                    order: 0,
                    parent_id: null,
                },
            ],
        },
    },
});

export const coreMenuState = atom({
    key: "coreMenuState",
    default: {
        loaded: false,
        error: false,
        res: {
            data: [
                {
                    children: [],
                    name: "",
                    url: "",
                    target: "",
                    nested: 0,
                    order: 0,
                    parent_id: null,
                },
            ],
        },
    },
});

export const fgdMenuState = atom({
    key: "fgdMenuState",
    default: {
        loaded: false,
        error: false,
        res: {
            data: [],
        },
    },
});

export const bypassSplashState = atom({
    key: "bypassSplashState",
    default: false,
});

export const pageLoadedState = atom({
    key: "pageLoadedState",
    default: false,
});

export const filterDatesState = atom({
    key: "filterDateState",
    default: {
        start: (dayjs("08/23/1989").unix() * 1000) as number,
        end: (dayjs().unix() * 1000) as number,
    },
});

export const magicBellInitState = atom({
    key: "magicBellInitState",
    default: true,
});

export const hideMenuTooltipState = atom({
    key: "hideMenuTooltip",
    default: false,
});